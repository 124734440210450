import { createSelector } from "@ngrx/store";
import { AppState } from "../store/app.reducer";
import { ProjectState } from "./project.reducer";

export const selectFeature = (state: AppState) => state.project;

export const selectClientId = createSelector(
  selectFeature,
  (state: ProjectState) => state.clientId
)

export const selectProject = createSelector(
  selectFeature,
  (state: ProjectState) => ({projectId: state.projectId, name: state.projectName})
)

export const selectLogo= createSelector(
  selectFeature,
  (state: ProjectState) => state.logo
)