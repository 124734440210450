import {createReducer, on} from "@ngrx/store";
import * as project from './project.actions';

export interface ProjectState {
	projectName: string;
	projectId: number;
	clientId: number;
	logo: string;
}

export const initialState: ProjectState = {
	projectId: -1,
	projectName: '',
	clientId: -1,
	logo: ''
}

export const projectReducer = createReducer(
	initialState,
	on(project.setProject, (state, {project}) => ({...state, ...project})),
	on(project.setClient, (state, {clientId}) => ({...state, clientId})),
	on(project.setLogo, (state, {logo}) => ({...state, logo})),
	on(project.loadProject, (state, {project}) => ({...state, ...project})),
	on(project.unsetProject, (state) => ({...state, ...initialState})),
)
