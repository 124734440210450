import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { Business } from 'src/app/auth/models/login.model';
import { Client, Project } from '../../models/header.model';
import { setClient, setLogo, setProject, unsetProject } from '../../../project-management/project.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { selectclientList } from 'src/app/auth/auth.selectors';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  clientsBusiness: Business[] = [];
  clients: Client[] = [];
  project: Project[] = [];
  clientSelected: number | null = null;
  nameClient: string = '';
  projectSelected: number | null = null;
  nameProject: string = '';
  logo: string = '';
  destroyed$ = new Subject();

  constructor(private store: Store<AppState>,
    private router: Router) { }

  ngOnInit(): void {
    this.store.select(selectclientList).pipe(takeUntil(this.destroyed$)).subscribe((clients) => {
      if (clients) {
        this.clientsBusiness = [...clients];
        this.clients = this.clientsBusiness.map(item => {
          return {
            id: item.id,
            name: item.traderName,
            select: this.clientSelected === item.id,
            url: item.logo
          }
        });
        if (this.clientSelected) {
          const idx = this.clientsBusiness.findIndex(item => item.id === this.clientSelected);
          this.project = this.clientsBusiness[idx].businessList.map(item => {
            return {
              id: item.id,
              name: item.name,
              select: this.projectSelected === item.id
            }
          });
        }
        if (this.clients.length === 1) {
          this.selectClient(true, this.clients[0], true)
        }
      }
    });
    this.store.select('project').pipe(takeUntil(this.destroyed$)).subscribe(({projectId, clientId}) => {
      if (projectId > -1 && clientId > -1 && this.clientsBusiness.length > 0) {
        this.clientSelected = clientId;
        this.projectSelected = projectId;
        const idx = this.clientsBusiness.findIndex(item => item.id === clientId);
        if (idx === -1) return;
        // this.nameClient = this.clientsBusiness[idx].name;
        const clientSelected = this.clients.find(item => item.id === clientId);
        if (clientSelected) {
          this.nameClient = clientSelected.name;
          clientSelected.select = true;
        }
        this.project = this.clientsBusiness[idx].businessList.map(item => {
          if (item.id === this.projectSelected) {
            this.nameProject = item.name
          }
          return {
            id: item.id,
            name: item.name,
            select: item.id === this.projectSelected
          }
        });
      } else if (projectId === -1 && clientId === -1) {
        this.clearAll(false)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  selectClient (check: boolean, client: Client, projectAuto = false) {
    if (check) {
      this.clientSelected = client.id;
      this.nameClient = client.name;
      const idx = this.clientsBusiness.findIndex(item => item.id === client.id);
      this.project = this.clientsBusiness[idx].businessList.map(item => {
        return {
          id: item.id,
          name: item.name,
          select: false
        }
      });
      this.logo = client.url;
      this.store.dispatch(setClient({clientId: this.clientSelected}));
      this.store.dispatch(setLogo({logo: client.url ?? ''}));
      if (this.project.length === 1 && projectAuto) {
        this.selectProject(true, this.project[0])
      }
    } else {
      this.clearAll();
    }
    if (!projectAuto) {
      localStorage.removeItem('project');
      this.navigateHome();
    }
  }

  selectProject (check: boolean, project: Project) {
    if (check) {
      this.projectSelected = project.id;
      this.nameProject = project.name;
      this.store.dispatch(setProject({project: {projectId: this.projectSelected, projectName: this.nameProject}}))
      const data = {
        projectId: this.projectSelected,
        clientId: this.clientSelected,
        projectName: this.nameProject,
        logo: this.logo
      }
      localStorage.setItem('project', btoa(JSON.stringify(data)));
    } else {
      this.projectSelected = null;
      this.nameProject = '';
      this.store.dispatch(setProject({project: {projectId: -1, projectName: ''}}))
      localStorage.removeItem('project');
      this.navigateHome();
    }
  }

  navigateHome () {
    const url = this.router.url.split('/')[-1];
    if (url !== 'home') {
      this.router.navigate(['/admin/home'])
    }
  }

  clearAll (store = true) {
    this.clientSelected = null;
    this.project = [];
    this.projectSelected = null;
    this.nameProject = '';
    this.nameClient = '';
    if (store) {
      this.store.dispatch(unsetProject());
    }
    console.log('clear')
  }

}
