import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoModal } from '../../models/modals/info-modal';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.css']
})
export class InfoModalComponent implements OnInit {
  @Output() response: EventEmitter<any> = new EventEmitter<any>();
  @Input() data: InfoModal;
	color: any;
	icon: any;
	iconStyles: Record<string, string> = {};
	iconStylesConfirm: Record<string, string> = {};
	iconStylesCancel: Record<string, string> = {};

  constructor(private modalservice:NgbActiveModal) { }

  ngOnInit(): void {
		this.color = this.data.type

		if (this.data.type == 'success'){
			this.color = 'var(--success-new)';
			this.icon = 'check_circle';

		} else if (this.data.type == 'warning'){
			this.color = 'var(--warning)';
			this.icon = 'error';

		} else if (this.data.type == 'error') {
			this.color = 'var(--error)';
			this.icon = 'error';
		} else if (this.data.type == 'question') {
			this.color = 'var(--info)';
			this.icon = 'question_mark';
			this.setCurrentStyles()
		} else if (this.data.type == 'question_orange') {
			this.color = 'var(--orange-l)';
			this.icon = 'question_mark';
			this.setCurrentStyles()
		} else {
			this.color = 'var(--info)';
			this.icon = 'info';
		}
  }

	setCurrentStyles() {
		// CSS styles: set per current state of component properties
		this.iconStyles = {
			'border-radius': '50%',
			'background-color': this.color,
			'color': '#FFF'
		};
	}

  closeModal(value: boolean){
    this.response.emit({action: value});
  }

}
