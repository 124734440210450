import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

	private readonly API = environment.API.LOGIN;
	private readonly API_NEW_LOGIN = environment.API.NEW_LOGIN;
	private readonly API_URL = environment.API.BASE_API;

  constructor(private http: HttpClient) { }

	getMenuOptions(data: any) {
		return this.http.post(`${this.API}/permissions`, data).toPromise();
	}

	getNewMenu (idUser: number) {
		return this.http.get(`${this.API_NEW_LOGIN}/permissions/${idUser}`).toPromise();
	}

	getDashboard () {
		return this.http.get(`${this.API_URL}/quicksight/url`).toPromise();
	}
}
