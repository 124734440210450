<div class="header">
  <div class="d-flex w-50 justify-content-end">
    <button mat-button class="clients" (click)="client.toggle($event)" [disabled]="clients.length === 1">
      <span [style.font-weight]="clientSelected !== null ? '400' : '600'">
        {{clientSelected !== null ? 'Cliente:' : 'Seleccionar Cliente:'}}
      </span>
      {{ nameClient }}
      <mat-icon>expand_more</mat-icon>
    </button>
    <p-overlayPanel #client styleClass="panel-client">
      <ng-template pTemplate>
          <div class="d-flex align-items-center justify-content-between py-3 px-4 item-client" *ngFor="let client of clients">
            <span>{{client.name}}</span>
            <p-inputSwitch class="switch-custom orange" [(ngModel)]="client.select" [disabled]="clientSelected !== null && clientSelected !== client.id" (onChange)="selectClient($event.checked, client)"></p-inputSwitch>
          </div>
      </ng-template>
    </p-overlayPanel>
  </div>
  <div class="v-line">
  </div>
  <div class="d-flex w-50 justify-content-start">
    <button mat-button class="clients" (click)="projectPanel.toggle($event)" [disabled]="clientSelected === null || (this.clients.length === 1 && this.project.length === 1)">
      <span [style.font-weight]="projectSelected !== null ? '400' : '600'">
        {{projectSelected !== null ? 'Proyecto:' : 'Seleccionar Proyecto:'}}
      </span>
      {{ nameProject | format: 30 }}
      <mat-icon>expand_more</mat-icon>
    </button>
    <p-overlayPanel #projectPanel styleClass="panel-client">
      <ng-template pTemplate>
        <div class="d-flex align-items-center justify-content-between py-3 px-4 item-client" *ngFor="let p of project">
          <span>{{p.name}}</span>
          <p-inputSwitch class="switch-custom orange" [(ngModel)]="p.select" [disabled]="projectSelected !== null && projectSelected !== p.id" (onChange)="selectProject($event.checked, p)"></p-inputSwitch>
        </div>
      </ng-template>
    </p-overlayPanel>
  </div>
  <div class="header-message" *ngIf="projectSelected === null">
    Para acceder al menú lateral, te invitamos a seleccionar primero un cliente y posteriormente elegir el proyecto correspondiente.
  </div>
</div>
